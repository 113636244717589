import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { locale, loadMessages } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';

import { getDictionary } from '../localization/services';

const langs = ['fr', 'en', 'ar'] as const;
const storageKey = 'app-lang';

export type Lang = typeof langs[number];

function getNextLang(lang?: Lang) {
  const ret = langs[langs.indexOf(lang as Lang) + 1] || langs[0];
  locale(ret);
  notify(ret, 'success', 600);
  window.location.href = window.location.href + String();
  return ret;
}

function getCurrentLang(): Lang {
  return window.localStorage[storageKey] || getNextLang() || langs[0];
}

async function setAppLang(newlang?: Lang) {
  const langName = newlang || getCurrentLang();
  locale(langName);
  window.localStorage[storageKey] = langName;
}

export function useLangContext() {
  const [lang, setLang] = useState(getCurrentLang());
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if(window.localStorage[storageKey] == undefined) {
      setLang(langs[0]);
    }
    loadMessages(getDictionary);
    setIsLoaded(true);
  }, []);

  const switchLang = useCallback(() => setLang((currentLang: Lang) => getNextLang(currentLang)), [getNextLang]);

  useEffect(() => {
    isLoaded && setAppLang(lang);
  }, [lang, isLoaded]);

  return useMemo(()=> ({ lang, switchLang, isLoaded }), [lang, isLoaded]);
}

export const LangContext = React.createContext<ReturnType<typeof useLangContext> | null>(null);
