import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { getDictionary } from './localization/services';

const storageKey = 'app-lang';
const getLocale = () => {
  const storageLocale = window.localStorage[storageKey];
  return storageLocale != null ? storageLocale : 'fr';
};

const initMessages = () => {
  loadMessages(getDictionary);
};
//setLocale('fr');
locale(getLocale());
initMessages();

export const navigation = [
  {
    text: formatMessage('Gestion_dossier'),
    icon: 'user',
    path: '/dashboard',
    isExpanded: true,
    items: [
      {
        text: formatMessage('Patients'),
        path: '/patients',
      },
      {
        text: formatMessage('Dossiers'),
        path: '/dossiers',
      },
      {
        text: 'Dossiers du jours',
        path: '/dossiers_jours',
      },
      {
        text: 'Validation',
        path: '/validation',
      },
    ],
  },
  {
    text: formatMessage('Regle_organisation'),
    icon: 'event',
    path: '',
    isExpanded: false,
    items: [
      {
        text: formatMessage('Paillasses'),
        path: '/paillasses',
      },
      {
        text: formatMessage('Prelevements'),
        path: '/prelevements',
      },
      {
        text: "Familles d'analyses",
        path: '/familles-analyses',
      },
      {
        text: 'Analyses',
        path: '/Analyses',
      },
    ],
  },
  {
    text: formatMessage('Antibiogrammes'),
    icon: 'event',
    path: '',
    isExpanded: false,
    items: [
      {
        text: "Familles d'antibiotiques",
        path: '/Familles-Antibiotiques',
      },
      {
        text: 'Antibiotiques',
        path: '/Antibiotiques',
      },
      {
        text: 'Familles de germes',
        path: '/familles-germes',
      },
      {
        text: 'Germes',
        path: '/Germes',
      },
    ],
  },
  {
    text: 'Analytics',
    icon: 'chart',
    path: '',
    isExpanded: false,
    items: [
      {
        text: 'Dashboard',
        path: '/analytics-dashboard',
      },
      {
        text: 'Sales Report',
        path: '/analytics-sales-report',
      },
      {
        text: 'Geography',
        path: '/analytics-geography',
      },
    ],
  },
];
