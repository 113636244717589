import React, { useMemo, useCallback } from 'react';
import { useAuth } from '../../../contexts/auth';
import List from 'devextreme-react/list';

import './UserMenuSection.scss';
import type { UserMenuSectionProps } from '../../../types';

import { locale, loadMessages, formatMessage } from 'devextreme/localization';

import { getDictionary } from '../../../localization/services';

export const UserMenuSection = ({ showAvatar, listRef }: UserMenuSectionProps) => {
  const { user, signOut } = useAuth();
  const storageKey = 'app-lang';
  const getLocale = () => {
    const storageLocale = window.localStorage[storageKey];
    return storageLocale != null ? storageLocale : 'fr';
  };

  const initMessages = () => {
    loadMessages(getDictionary);
  };
  //setLocale('fr');
  locale(getLocale());
  initMessages();

  const menuItems = useMemo(
    () => [
      {
        text: formatMessage('account'),
        icon: 'preferences',
        //onClick: signOut,
      },
      {
        text: formatMessage('messages'),
        icon: 'message',
        //onClick: signOut,
      },
      {
        text: formatMessage('training'),
        icon: 'info',
        //onClick: signOut,
      },
      {
        text: formatMessage('aide'),
        icon: 'help',
        //onClick: signOut,
      },
      {
        text: formatMessage('Logout'),
        icon: 'logout',
        onClick: signOut,
      },
    ],
    [signOut]
  );

  const listElementAttr = {
    class: 'user-info-list'
  };

  const onItemClick = useCallback(({ itemData }) => { itemData.onClick(); console.log(itemData.text); }, []);

  return (
    <>
      <div className='user-info'>
        { showAvatar && (
          <div className='image-container'>
            <div
              style={{
                backgroundImage: `url(${user?.avatarUrl})`,
              }}
              className='user-image'
            />
          </div>
        )}
        <div className='user-name'>{user?.name}</div>
      </div>
      <List ref={listRef} elementAttr={listElementAttr} onItemClick={onItemClick} items={menuItems} />
    </>
  );
};
