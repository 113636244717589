const getLocales = [
  {
    Name: 'Francais',
    Value: 'fr',
  },
  {
    Name: 'English',
    Value: 'en',
  },
  {
    Name: 'Arabic',
    Value: 'ar',
  },
];

const getDictionary = {
  fr: {
    add: 'Ajouter',
    edit: 'Modifier',
    delete: 'Supprimer',
    save: 'Sauve',
    cancel: 'Annuler',
    search: 'Recherche ...',
    Number: 'Nombre',
    Name: 'Nom',
    Phone: 'Téléphone',
    Contact: 'personne de contact',
    Company: 'Entreprise',
    Amount: 'Montant',
    PaymentDate: 'Date de règlement',
    Password: 'Mot de passe',
    ConfirmPassword: 'Confirmez le mot de passe',
    SignIn: "S'identifier",
    ForgotPassword: 'Mot de passe oublié?',
    SignUp: "S'inscrire",
    ResetPassword: 'Réinitialiser le mot de passe',
    ChangePassword: 'Changer le mot de passe',
    CreateAnAccount: 'Créer un compte',
    RememberMe: 'Se souvenir de moi',
    HaveAnAccount: 'Vous avez un compte ?',
    CreateNewAccount: 'Créer un nouveau compte',
    EmailIsRequired: 'Email est requis',
    EmailIsInvalid: 'Email est invalide',
    PasswordIsRequired: 'Mot de passe est requise',
    PasswordsDoNotMatch: 'Les mots de passe ne correspondent pas',
    ResetPassword_description: "Veuillez entrer l'adresse e-mail que vous avez utilisée pour vous inscrire et nous vous enverrons un lien pour réinitialiser votre mot de passe par e-mail.",
    Gestion_dossier: 'Gestion des dossiers',
    Regle_organisation: "Régle d'organisation",
    Dossiers: 'Dossiers',
    Dossier: 'Dossier',
    Patients: 'Patients',
    Patient: 'Patient',
    patient_new: 'Nouveau patient',
    Medecins: 'Médecins',
    Medecin: 'Médecin',
    Prelevements: 'Prelevements',
    Prelevement: 'Prelevement',
    Paillasses: 'Paillasses',
    Antibiogrammes: 'Antibiogrammes',
    account: 'Compte',
    messages: 'Messages',
    training: 'Formation',
    aide: 'Aide?',
    Logout: 'Se déconnecter',
  },
  en: {
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    save: 'Save',
    cancel: 'Cancel',
    search: 'Search ...',
    Number: 'Number',
    Name: 'Name',
    Phone: 'Phone',
    Contact: 'Contact',
    Company: 'Company',
    Amount: 'Amount',
    PaymentDate: 'Payment Date',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    SignIn: 'Sign In',
    ForgotPassword: 'Forgot password?',
    SignUp: 'Sign Up',
    ResetPassword: 'Reset Password',
    ChangePassword: 'Change Password',
    CreateAnAccount: 'Create an account',
    RememberMe: 'Remember me',
    HaveAnAccount: 'Have an account?',
    CreateNewAccount: 'Create a new account',
    EmailIsRequired: 'Email is required',
    EmailIsInvalid: 'Email is invalid',
    PasswordIsRequired: 'Password is required',
    PasswordsDoNotMatch: 'Passwords do not match',
    ResetPassword_description: 'Please enter the email address that you used to register, and we will send you a link to reset your password via Email.',
    Gestion_dossier: 'File management',
    Regle_organisation: 'Organizational regulation',
    Dossiers: 'Files',
    Dossier: 'File',
    Patients: 'Patients',
    Patient: 'Patient',
    patient_new: 'New Patient',
    Medecins: 'Doctors',
    Medecin: 'Doctor',
    Prelevements: 'Specimens',
    Prelevement: 'Specimen',
    Paillasses: 'Benches',
    Antibiogrammes: 'Antibiograms',
    account: 'Account',
    messages: 'Messages',
    training: 'Training',
    aide: 'Helps?',
    Logout: 'Logout',
  },
  ar: {
    add: 'يضيف',
    edit: 'يحرر',
    delete: 'يمسح',
    save: 'آمن',
    cancel: 'يلغي',
    search: 'بحث ...',
    Number: 'رقم',
    Name: 'اسم',
    Phone: 'هاتف',
    Contact: 'اسم',
    Company: 'منظمة',
    Amount: 'مجموع',
    PaymentDate: 'تاريخ السداد',
    SignIn: 'تسجيل الدخول',
    ForgotPassword: 'هل نسيت كلمة السر؟',
    SignUp: 'اشتراك',
    ResetPassword: 'إعادة تعيين كلمة المرور',
    ChangePassword: 'تغيير كلمة المرور',
    CreateAnAccount: 'إنشاء حساب',
    RememberMe: 'تذكرنى',
    HaveAnAccount: 'هل لديك حساب؟',
    CreateNewAccount: 'انشاء حساب جديد',
    EmailIsRequired: 'البريد الالكتروني مطلوب',
    EmailIsInvalid: 'البريد الإلكتروني غير صالح',
    PasswordIsRequired: 'كلمة المرور مطلوبة',
    PasswordsDoNotMatch: 'كلمة المرور غير مطابقة',
    ResetPassword_description: 'الرجاء إدخال عنوان البريد الإلكتروني الذي استخدمته للتسجيل ، وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك عبر البريد الإلكتروني.',
    Gestion_dossier: 'إدارة السجلات',
    Regle_organisation: 'التنظيم التنظيمي',
    Dossiers: 'الطلبات',
    Dossier: 'طلبت',
    Patients: 'مرضى',
    Patient: 'مريض',
    patient_new: 'مريض جديد',
    Medecins: 'الأطباء',
    Medecin: 'طبيب',
    Prelevements: 'العينات',
    Prelevement: 'اقتطاع',
    Paillasses: 'مقاعد',
    Antibiogrammes: 'المضادات الحيوية',
    account: 'حساب',
    messages: 'رسائل',
    training: 'تشكيل',
    aide: 'مساعد?',
    Logout: 'تسجيل خروج',
  },
};

export { getLocales, getDictionary };
