//import { defaultUser } from '../utils/default-user';
import config from '../config.json';
const baseUrl = '/Authentication/Authenticate';

/* eslint-disable @typescript-eslint/no-unused-vars */
export async function signIn(userName:string, password: string) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
      withCredentials: true,
      crossdomain: true,
      body: JSON.stringify({ userName, password }),
    };

    const LOGIN_URL = config.base_url + baseUrl;
    const response = await fetch(LOGIN_URL, requestOptions);
    const data = await response.json();
    if (response.ok) {
      console.log(data);
      const defaultUser = { email:userName, name: data.name, avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/13.png', };
      localStorage.setItem('userProfile', JSON.stringify(defaultUser));
      return {
        isOk: true,
        data: { ...defaultUser, userName },
      };
    }
    else{

      if(response.status == 401)
      {
        return {
          isOk: false,
          message: 'Authentication failed',
        };
      }
      else{
        return {
          isOk: false,
          message: 'Serveur erreur',
        };
      }
    }

  } catch (err) {
    return {
      isOk: false,
      message: 'net::ERR_CONNECTION_REFUSED',
    };
  }
}

export async function signIn2(email: string, password: string) {
  try {
    // Send request
    if (email !== 'slim.mtibaa@gmail.com' || password !== '123456')
    {
      return {
        isOk: false,
        message: 'Authentication failed',
      };
    }
    const defaultUser = { email:email, name: email, avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/13.png', };
    localStorage.setItem('userProfile', JSON.stringify(defaultUser));
    return {
      isOk: true,
      data: { ...defaultUser, email },
    };
  } catch {
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function getUser() {
  try {

    // Send request
    const userProfile = localStorage.getItem('userProfile');

    if(userProfile == undefined)
    {
      return {
        isOk: false,
        message: 'Authentication failed',
      };
    }
    else
    {
      const user = JSON.parse(userProfile);
      return {
        isOk: true,
        data: user,
      };
    }

  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    return {
      isOk: true,
      data: { email },
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}
