import React, { useState, useEffect } from 'react';

import './timerTicker.scss';

export const TimerTick = () => {
  const [dateTime, setDateTime] = useState(new Date().toLocaleString());
  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='timeTick'>
      <p>{dateTime}</p>
    </div>
  );
};

export const CleatTimerTick = () => {
  const [dateTime, setDateTime] = useState(new Date().toLocaleString());
  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className='timeTick-info'>
      <div> </div>
      <b>{dateTime}</b>
    </div>
  );
};
