import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { useAuth } from '../../../contexts/auth';
import { isMobile } from 'react-device-detect';

export const MouseController = () => {

  const navigate = useNavigate();

  const onLogoutClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);
  const { signOut } = useAuth();

  const onTimeElipsed = useCallback(() => {
    if(seconds <= 1 && !isMobile)
    {
      setSeconds(defaultTimeOut);
      [signOut];
      onLogoutClick();
    }
  });
  const defaultTimeOut = 120;
  const [seconds, setSeconds] = useState(defaultTimeOut);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);
    const handleMouseMove = (event) => {
      setSeconds(defaultTimeOut);
      //console.log(event.button);
      if (event.button === 2) {
        //event.preventDefault();
      }
    };
    window.addEventListener('mouseup', handleMouseMove);
    document.addEventListener('contextmenu', handleMouseMove);
    return () => { clearInterval(interval);
      onTimeElipsed();
      window.removeEventListener('mouseup', handleMouseMove);
      document.removeEventListener('contextmenu', handleMouseMove);
    };
  }, [onTimeElipsed]);

  if (!isMobile)
  {
    return (
      <div>
        <Button className='mouse-button'
          icon={`icons/battery/${seconds > 90 ? 'full' : (seconds < 30 ? 'low' : 'medium')}.svg`}
          text={seconds}
          onClick={onLogoutClick} />
      </div>
    );
  }
  else {
    return (
      <div />
    );
  }

};
