import React, { useMemo } from 'react';

import DropDownButton from 'devextreme-react/drop-down-button';

import notify from 'devextreme/ui/notify';

import { locale } from 'devextreme/localization';

const storageKey = 'app-lang';
const buttonDropDownOptions = { width: 140 };
const modeSelection = true;

export const Flags = () => {

  const menuItems = useMemo(
    () => [
      {
        id: 'fr',
        text: 'Francais',
        icon: './icons/flags/fr-flag.svg',
      },
      {
        id: 'en',
        text: 'English',
        icon: './icons/flags/en-flag.svg',
      },
      {
        id: 'ar',
        text: 'Arabic',
        icon: './icons/flags/ar-flag.svg',
      },
    ],
    []
  );

  const getLocale = () => {
    const storageLocale = window.localStorage[storageKey];
    return storageLocale != null ? storageLocale : 'fr';
  };

  const setLocale = (savingLocale) => {
    window.localStorage[storageKey] = savingLocale;
    locale(savingLocale);
  };

  const onItemClick = (e) => {
    setLocale(e.item.id);
    notify(e.item.text || e.item, 'success', 600);
    window.location.href = window.location.href + String();
  };

  return (
    <div className ='flagClass'>
      <DropDownButton
        dropDownOptions={buttonDropDownOptions}
        items={menuItems}
        useSelectMode={modeSelection}
        displayExpr='text'
        keyExpr='id'
        selectedItemKey={getLocale()}
        //icon={getLocalePng()}
        onSelectionChanged={onItemClick}
      />
    </div>
  );
};
