import React, { useState, useRef, useCallback } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { LoginOauth } from '../login-oauth/LoginOauth';
import Button from 'devextreme-react/button';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useAuth } from '../../../contexts/auth';

import * as enMessages from 'devextreme/localization/messages/en.json';
import * as frMessages from 'devextreme/localization/messages/fr.json';

import { locale, loadMessages, formatMessage } from 'devextreme/localization';

import { getDictionary } from '../../../localization/services';

import './LoginForm.scss';

import { TimerTick } from '../timer-ticke/timerTicker';

export const LoginForm = ({ resetLink, createAccountLink }) => {
  const storageKey = 'app-lang';
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '' });

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();
      const { email, password } = formData.current;
      setLoading(true);

      const result = await signIn(email, password);
      if (result.isOk) {
        navigate('/');
      } else {
        setLoading(false);
        notify(result.message, 'error', 2000);
      }
    },
    [signIn]
  );

  const onCreateAccountClick = useCallback(() => {
    navigate(createAccountLink);
  }, [navigate]);

  const getLocale = () => {
    const storageLocale = window.localStorage[storageKey];
    return storageLocale != null ? storageLocale : 'fr';
  };

  //const setLocale = (savingLocale) => {
  //  sessionStorage.setItem('locale', savingLocale);
  //};

  const initMessages = () => {
    loadMessages(enMessages);
    loadMessages(frMessages);
    loadMessages(getDictionary);
  };
  //setLocale('fr');
  locale(getLocale());
  initMessages();

  return (
    <form className='login-form' onSubmit={onSubmit}>
      <Form
        formData={formData.current}
        disabled={loading}
        showColonAfterLabel
        showRequiredMark={false}
      >
        <Item dataField='email' editorType='dxTextBox' editorOptions={emailEditorOptions}>
          <RequiredRule message='Email is required' />
          <EmailRule message='Email is invalid' />
          <Label visible={false} />
        </Item>
        <Item dataField='password' editorType='dxTextBox' editorOptions={passwordEditorOptions}>
          <RequiredRule message='Password is required' />
          <Label visible={false} />
        </Item>
        <Item dataField='rememberMe' editorType='dxCheckBox' editorOptions={rememberMeEditorOptions}>
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width='100%' type='default' useSubmitBehavior>
            <span className='dx-button-text'>{loading ? <LoadIndicator width='24px' height='24px' visible /> : formatMessage('SignIn')}</span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <div className='reset-link'>
        <Link to={resetLink}>{formatMessage('ForgotPassword')}?</Link>
      </div>

      <Button
        className='btn-create-account'
        text={formatMessage('CreateAnAccount')}
        width='100%'
        onClick={onCreateAccountClick}
      />

      <LoginOauth />
      <TimerTick />
    </form>
  );
};

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email', value: 'contact@leaders-solutions.com' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: formatMessage('Password'), mode: 'password', value: 'password' };
const rememberMeEditorOptions = { text: formatMessage('RememberMe'), elementAttr: { class: 'form-text' } };
