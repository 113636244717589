import React, { useCallback, useContext } from 'react';
import Button from 'devextreme-react/button';
import { LangContext } from '../../../langue/lang';

export const LangSwitcher = () => {
  const langContext = useContext(LangContext);

  const RefreshPage = () => {
    console.log('page refresh');
    window.location.reload();
    window.location.href = window.location.href + String();
  };

  const onButtonClick = useCallback(() => {
    langContext?.switchLang();
    console.log(langContext?.lang);
    RefreshPage();
  }, [RefreshPage]);

  return <div>
    <Button className='lang-button'
      icon={`icons/Flags/${langContext?.lang !== undefined ? langContext?.lang : 'fr'}-flag.svg`}
      onClick={onButtonClick} />
  </div>;
};
